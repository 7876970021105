import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoboxTwo from '../components/global/InfoboxTwo';
import Padding from '../components/global/Padding/Padding';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Button from '../components/global/Button';
import Customers from '../components/global/customers/Customers';
import FeatureSnippetThree from '../components/global/FeatureSnippetThree';
import Faq from '../components/global/Faq';
import SiriWave from '../../utils/siriwave';
import RealEstateCTA from '../components/global/CTA/RealEstateCTA';
import { setColors, checkLink } from '../../utils/helpers';
import SEO from '../components/global/seo';
import * as S from '../components/newly-developed-pages/style';

const customStyles = {
	content: {
		position: 'relative',
		display: 'inline-block',
		margin: 'auto',
		inset: '0',
		boxSizing: 'border-box',
		outline: '0',
		padding: '0',
		border: 'none',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '900px',
	},
	overlay: {
		backgroundColor: setColors.modalOverLayColor,
		zIndex: '1050',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
		padding: '0 6px',
		display: 'flex',
	},
};

const RealEstateChatbot = ({ data }) => {
	const {
		heroHeadingText,
		heroTitle,
		heroSubtitle,
		heroButtonOneText,
		heroButtonOneLink,
		heroButtonTwoLink,
		heroButtonTwoText,
		heroIcon,
		heroImage,
		section1title,
		section1images,
		section2title,
		section2text,
		section2image,
		section2buttonText,
		section2buttonLink,
		section3image,
		section3logo,
		section3content,
		section4image,
		section4logo,
		section4content,
		section5backgroundVideo,
		section5foregroundVideo,
		section5mobileImage,
		section5logo,
		section5content,
		section6foregroundVideo,
		section6gif,
		section6mobileImage,
		section6logo,
		section6content,
		section7foregroundVideo,
		section7gif,
		section7mobileImage,
		section7logo,
		section7content,
		faqTitle,
		faqSubtitle,
		faqContent,
		section8title,
		section8subtitle,
		section8buttonText,
		section8buttonLink,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.contentfulRealEstateChatbot;
	const [modal, setModal] = useState(false);
	const handleOpenModal = () => {
		setModal(!modal);
	};
	const generateEmbedLink = link => {
		const url = new URL(link);
		const videoId = url.searchParams.get('v');
		return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
	};
	const generateButton = (link, text) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return (
				<Button btnColor={setColors.themePurple} to={link}>
					{text}
				</Button>
			);
		} else if (linkType === 1) {
			return (
				<Button
					btnColor={setColors.themePurple}
					as="a"
					href={link}
					target="_self"
				>
					{text}
				</Button>
			);
		} else if (linkType === 0) {
			const url = new URL(link);
			if (url.hostname === 'www.youtube.com') {
				return (
					<Button
						btnColor={setColors.themePurple}
						as="a"
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						onClick={e => {
							e.preventDefault();
							handleOpenModal();
						}}
					>
						{text}
					</Button>
				);
			} else {
				return (
					<Button
						btnColor={setColors.themePurple}
						as="a"
						href={link}
						target="_blank"
						rel="noopener noreferrer"
					>
						{text}
					</Button>
				);
			}
		}
	};
	useEffect(() => {
		new SiriWave({
			container: document.getElementById('siri-container'),
			width: 640,
			height: 200,
			style: 'ios',
			color: setColors.black,
			frequency: 6,
			amplitude: 1.5,
			curveDefinition: [
				{
					attenuation: -2,
					lineWidth: 1,
					opacity: 0.6,
					color: '229,231,233',
				},
				{
					attenuation: 2,
					lineWidth: 1,
					opacity: 0.8,
					color: '255,159,148',
				},
				{
					attenuation: 1,
					lineWidth: 1.5,
					opacity: 1,
					color: '109,111,189',
				},
			],
		}).setSpeed(0.03);
	}, []);
	return (
		<Layout>
			<S.HeroWrapper>
				<InfoboxTwo
					heading={heroHeadingText}
					title={heroTitle}
					subtitle={heroSubtitle}
					buttonOneText={heroButtonOneText}
					buttonOneLink={heroButtonOneLink}
					buttonTwoText={heroButtonTwoText}
					buttonTwoLink={heroButtonTwoLink}
					icon={heroIcon}
					isHeroHeader
					image={heroImage}
				/>
				<S.SiriWrapper>
					<div id="siri-container"></div>
				</S.SiriWrapper>
			</S.HeroWrapper>
			<S.SectionWrapper>
				<S.CustomerWrapper>
					<Customers
						section2Images={section1images}
						section2Title={section1title}
					/>
				</S.CustomerWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.BgWrapper>
					<Padding padding="100" />
					<S.ContentWrapper>
						<WrapperOne wrapperClass="disable-grid-mobile">
							<div className="section-header">
								<SectionTextBox
									title={section2title}
									richTextContent={section2text}
								/>
							</div>
							<div style={{ padding: '30px 15px' }}>
								<Img fluid={section2image.fluid} alt={section2image.title} />
							</div>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '10px 0 0 0',
								}}
							>
								{generateButton(section2buttonLink, section2buttonText)}
							</div>
							<Padding padding="100" />
						</WrapperOne>
					</S.ContentWrapper>
					<Padding padding="70" />
				</S.BgWrapper>
				<S.StyledReactModal
					isOpen={modal}
					closeTimeoutMS={500}
					shouldFocusAfterRender={false}
					shouldCloseOnOverlayClick={true}
					style={customStyles}
					onRequestClose={handleOpenModal}
					portalClassName="portal-class"
				>
					<div className={`modal-content modal-default ${modal && 'open'}`}>
						<button onClick={handleOpenModal} className="close-button">
							×
						</button>
						<iframe
							title="youtubeVideoEmbedded"
							src={generateEmbedLink(section2buttonLink)}
							allow="autoplay;fullscreen;"
							frameborder="0"
							allowfullscreen="true"
							webkitallowfullscreen="true"
							mozallowfullscreen="true"
						></iframe>
					</div>
				</S.StyledReactModal>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section3image}
							invert="true"
							logo={section3logo}
							content={section3content}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section4image}
							logo={section4logo}
							content={section4content}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							invert="true"
							logo={section5logo}
							content={section5content}
							mediaType="bgVideo"
							bgVideo={section5backgroundVideo}
							fgVideo={section5foregroundVideo}
							mobileImage={section5mobileImage}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							logo={section6logo}
							content={section6content}
							mediaType="gif"
							fgVideo={section6foregroundVideo}
							gif={section6gif}
							mobileImage={section6mobileImage}
							gifType="gear"
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							invert="true"
							logo={section7logo}
							content={section7content}
							mediaType="gif"
							fgVideo={section7foregroundVideo}
							gif={section7gif}
							mobileImage={section7mobileImage}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.FaqWrapper>
					<S.BGWrapperTwo>
						<Padding padding="100" />
						<Padding padding="100" />
						<WrapperOne disableBottomMargin="true">
							<Faq
								title={faqTitle}
								subtitle={faqSubtitle}
								content={faqContent}
								largeTitle="true"
								primaryColor={setColors.themePurple}
								maxCount="10"
							/>
						</WrapperOne>
						<Padding padding="120" />
					</S.BGWrapperTwo>
				</S.FaqWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<RealEstateCTA
						title={section8title}
						subtitle={section8subtitle}
						buttonText={section8buttonText}
						buttonLink={section8buttonLink}
					/>
				</WrapperOne>
			</S.SectionWrapper>
			<S.GlobalStyle />
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
		</Layout>
	);
};

export default RealEstateChatbot;

export const pageQuery = graphql`
	{
		contentfulRealEstateChatbot {
			heroHeadingText
			heroTitle
			heroSubtitle
			heroButtonOneText
			heroButtonOneLink
			heroButtonTwoLink
			heroButtonTwoText
			heroIcon {
				fixed(height: 30, width: 128, quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			heroImage {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section1title
			section1images {
				fluid(quality: 10) {
					...GatsbyContentfulFluid
				}
				title
			}
			section2title
			section2text {
				raw
			}
			section2image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section2buttonText
			section2buttonLink
			section3image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section3logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section3content {
				title
				subtitle
				linkText
				link
			}
			section4image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section4logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section4content {
				title
				subtitle
				linkText
				link
			}
			section5backgroundVideo {
				file {
					url
					contentType
				}
			}
			section5foregroundVideo {
				file {
					url
					contentType
				}
			}
			section5mobileImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section5logo {
				fixed {
					src
				}
				file {
					details {
						image {
							width
							height
						}
					}
				}
				title
			}
			section5content {
				title
				subtitle
				linkText
				link
			}
			section6foregroundVideo {
				file {
					url
					contentType
				}
			}
			section6gif {
				file {
					url
				}
				title
			}
			section6mobileImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section6logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section6content {
				title
				subtitle
				linkText
				link
			}
			section7foregroundVideo {
				file {
					url
					contentType
				}
			}
			section7gif {
				file {
					url
				}
				title
			}
			section7mobileImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section7logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section7content {
				title
				subtitle
				linkText
				link
			}
			faqTitle
			faqSubtitle {
				raw
			}
			faqContent {
				question
				answer {
					type
					content
				}
			}
			section8title
			section8subtitle {
				raw
			}
			section8buttonText
			section8buttonLink
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;
